import { Flat } from 'types/domain'

const removeDuplicatesByProperty = (array: any[], property: string) => {
  const uniqueObjects = []
  const uniqueValues = new Set()

  for (const obj of array) {
    const value = obj[property]

    if (!uniqueValues.has(value)) {
      uniqueObjects.push(obj)
      uniqueValues.add(value)
    }
  }

  return uniqueObjects
}

const useFavouritesInitialFormMessage = (favourites: Flat[]) => {
  if (favourites.length <= 0) return ''
  const investments = removeDuplicatesByProperty(
    favourites.map((flat) => {
      return {
        investmentName: flat.investmentNameWithStage.includes(
          'Dzielnica Lawendy'
        )
          ? 'Dzielnica Lawendy'
          : 'Miasteczko Jutrzenki',
        investmentNameWithStage: flat.investmentNameWithStage,
      }
    }),
    'investmentName'
  ).map((inv) => ({
    ...inv,
    flats: favourites.filter(
      (flat) => flat.investmentNameWithStage === inv.investmentNameWithStage
    ),
  }))
  return `Dzień dobry, interesuje mnie ${investments
    .map(
      (inv) =>
        `${inv.flats
          .map((flat: Flat) => `mieszkanie nr ${flat.number}`)
          .join(', ')} na osiedlu ${inv.investmentName}`
    )
    .join(', ')}.`
}

export default useFavouritesInitialFormMessage
