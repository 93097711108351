import React from 'react'
import { PageProps, graphql } from 'gatsby'
import styled from 'styled-components'

import { Container } from 'components/atoms/Container'
import { H1Light } from 'components/atoms/Typography'
import { Seo } from 'components/atoms/Seo'
import { Breadcrumbs } from 'components/modules/Breadcrumbs'
import { Contact } from 'components/organisms/Contact'
import { FlatSearch } from 'components/organisms/SearchFlat/FlatSearch/FlatSearch'

import { Layout } from 'views/Layout'

import media from 'styles/media'
import useFlatContext from 'hooks/useFlatContext'
import useFavouritesInitialFormMessage from 'hooks/useFavouritesInitialFormMessage'

const BreadcrumbsWraper = styled(Container)`
  max-width: calc(1136px + 40px);

  margin-top: 110px;

  ${media.sm.max} {
    margin-top: 68px;
    padding: 0px;
  }
`

const TextContainer = styled(Container)`
  width: 100%;

  display: flex;
  flex-direction: column;

  ${media.lg.max} {
    padding-bottom: 48px;
  }
  ${media.sm.max} {
    padding: 48px 24px;
  }
`

const FlatsContainer = styled.div`
  margin: 0 2rem;
  padding: 6rem 2rem;

  background-color: #f3f3f3;

  ${media.md.max} {
    margin: 0;
    padding: 3rem 1rem;
  }
`

const FavouritesPage: React.FC<PageProps<Queries.FavouritesPageQuery>> = ({
  data,
}) => {
  const PAGE = data.wpPage?.fav
  const PAGE_SEO = data.wpPage?.seo

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const { favourites } = useFlatContext()

  const initialFormMessage = useFavouritesInitialFormMessage(favourites)

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />

      <BreadcrumbsWraper margin={[0, 'auto']} padding={[0, 20]}>
        <Breadcrumbs crumbs={[{ name: 'Ulubione' }]} />
      </BreadcrumbsWraper>

      <TextContainer padding={[72, 50]} margin={[0, 'auto']}>
        <H1Light
          align="center"
          dangerouslySetInnerHTML={{ __html: PAGE.favouritesHeroTitle! }}
        />
      </TextContainer>

      <FlatsContainer>
        <Container margin={[0, 'auto']}>
          <FlatSearch flats={favourites} heading="Lista mieszkań" />
        </Container>
      </FlatsContainer>

      <Contact
        heading={PAGE.favouritesFormTitle!}
        formTitle={PAGE.favouritesFormFormTitle!}
        image={{
          alt: PAGE.zdjecie?.altText!,
          src: PAGE.zdjecie?.localFile?.childImageSharp?.gatsbyImageData!,
        }}
        initialFormMessage={initialFormMessage}
      />
    </Layout>
  )
}

export default FavouritesPage

export const query = graphql`
  query FavouritesPage {
    wpPage(slug: { regex: "/schowek/" }) {
      title
      fav {
        favouritesFormFormTitle
        favouritesFormTitle
        favouritesHeroTitle
        zdjecie {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
      }
      seo {
        ...WpSEO
      }
    }
  }
`
